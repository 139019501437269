import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { ApprovalStatus, FormMode } from 'utils/types';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { archiveCampaign } from 'pages/campaigns/campaignManagement/components/campaignForm/utils/CampaignActions';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { offersSelection } from 'app/genericSlices/offers';
import { OfferFilters } from 'pages/offers/offerManagement/Offers.const';
import {
  CampaignActions,
  CampaignExtendedProps,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter.consts';
import { CampaignButtonProps } from 'components/shared/modal/Modal.const';
import { StyledButtonText, StyledIcon, StyledMenuItem } from './ButtonGroup.style';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

const ButtonGroup = ({ mode }: CampaignButtonProps) => {
  const { getValues, reset } = useFormContext();
  const campaignDetails = getValues() as CampaignExtendedProps;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { isDirty } = useFormState();
  const [offerVersion] = useWatch({
    name: ['offerVersion'],
  });
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty, mode]);

  const onActionClicked = async (actionName: CampaignActions, extraData?: any) => {
    const campaign = getValues() as CampaignExtendedProps;

    store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
    switch (actionName) {
      case CampaignActions.Edit:
        store.dispatch(
          openModal({
            modal: Modals.CampaignModal,
            props: { mode: FormMode.Edit, campaign: cloneDeep(campaign) },
          }),
        );
        reset(campaign);
        break;
      case CampaignActions.Archive: {
        await archiveCampaign(campaign, true);
        break;
      }
      case CampaignActions.Duplicate: {
        const dupCampaign = {
          ...pick(campaign, [
            'id',
            'type',
            'title',
            'description',
            'schedule',
            'locations',
            'voucherConfig',
            'restaurantEligibility',
            'isNational',
            'localSchedule',
            'isPriority',
            'isTopPriority',
          ]),
          isLocalCampaign: campaign.status === ApprovalStatus.Draft ? campaign.isLocalCampaign : false,
          offerVersion,
          tags: campaign.tags.map((t) => t.id),
          ...(campaign.isLocalCampaign
            ? {
                localSchedule: {
                  zone:
                    campaign.status === ApprovalStatus.Draft && campaign.localSchedule.zone
                      ? campaign.localSchedule.zone?.id
                      : null,
                  period: campaign.status === ApprovalStatus.Draft ? campaign.localSchedule.period : null,
                },
              }
            : {}),
        };
        store.dispatch(
          openModal({
            modal: Modals.CampaignModal,
            props: { mode: FormMode.Duplicate, campaign: cloneDeep(dupCampaign) },
          }),
        );
        reset(dupCampaign);
        break;
      }
      default:
    }
  };

  const DuplicateButton = (
    <MenuItem onClick={handleClose}>
      <StyledMenuItem>
        <StyledButtonText onClick={() => onActionClicked(CampaignActions.Duplicate)}>
          <StyledIcon>
            <LibraryAddIcon sx={{ fontSize: '20px' }} />
          </StyledIcon>
          Duplicate
        </StyledButtonText>
      </StyledMenuItem>
    </MenuItem>
  );

  const EditButton = (
    <MenuItem onClick={handleClose}>
      <StyledMenuItem>
        <StyledButtonText onClick={() => onActionClicked(CampaignActions.Edit)}>
          <StyledIcon>
            <EditIcon sx={{ fontSize: '20px' }} />
          </StyledIcon>
          Edit
        </StyledButtonText>
      </StyledMenuItem>
    </MenuItem>
  );

  const ArchiveButton = (
    <MenuItem onClick={handleClose}>
      <StyledMenuItem>
        <StyledButtonText onClick={() => onActionClicked(CampaignActions.Archive)}>
          <StyledIcon>
            <ArchiveIcon sx={{ fontSize: '20px' }} />
          </StyledIcon>
          Archive
        </StyledButtonText>
      </StyledMenuItem>
    </MenuItem>
  );

  const DeployedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {EditButton}
      {DuplicateButton}
    </RoleGuard>
  );

  const ActiveButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {EditButton}
      {DuplicateButton}
    </RoleGuard>
  );

  const DraftOrRejectedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {EditButton}
      {DuplicateButton}
      {ArchiveButton}
    </RoleGuard>
  );

  const ExpiredOrRevokedOrDeploymentFailedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {DuplicateButton}
      {ArchiveButton}
    </RoleGuard>
  );

  const ApprovedButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {EditButton}
      {DuplicateButton}
      {ArchiveButton}
    </RoleGuard>
  );

  const StopAssociationButtons = (
    <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
      {EditButton}
      {DuplicateButton}
    </RoleGuard>
  );

  const viewModeButtonsByStatus: Partial<Record<ApprovalStatus, JSX.Element>> = {
    [ApprovalStatus.Draft]: DraftOrRejectedButtons,
    [ApprovalStatus.Rejected]: DraftOrRejectedButtons,
    [ApprovalStatus.Deployed]: DeployedButtons,
    [ApprovalStatus.Active]: ActiveButtons,
    [ApprovalStatus.Expired]: ExpiredOrRevokedOrDeploymentFailedButtons,
    [ApprovalStatus.Revoked]: ExpiredOrRevokedOrDeploymentFailedButtons,
    [ApprovalStatus.DeploymentFailed]: ExpiredOrRevokedOrDeploymentFailedButtons,
    [ApprovalStatus.AssociationStopped]: StopAssociationButtons,
    [ApprovalStatus.PendingApproval]:ApprovedButtons,
    [ApprovalStatus.Approved]: ApprovedButtons,
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{ boxShadow: '0px 0px 8.3px 10px' }}
      >
        {viewModeButtonsByStatus[campaignDetails.status]}
      </Menu>
    </div>
  );
};

export default ButtonGroup;
