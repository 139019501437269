import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import ItemsSelectionAccordion from 'components/shared/accordion/itemsSelectionAccordion/ItemsSelectionAccordion';
import zIndex from 'styles/zIndex';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Headline = styled.div`
  font-family: ${newTypography.primaryFont};
  font-weight: 700;
  margin-bottom: ${newTypography.unit}px;
  font-size: 20px;
  line-height: 22.83px;
`;

export const SelectionContainer = styled.div<StyledWithThemeProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: visible;
  border-radius: ${typography.borderRadius}px;
  gap: 16px;

  align-items: flex-start;
  align-self: stretch;
`;

export const StyledItemsSelectionAccordion = styled(ItemsSelectionAccordion)<{ expanded: boolean, height?:number }>`
  display: flex;
  flex-direction: column;
  flex: ${({ expanded }) => `${expanded ? '1' : '0'})`};
  height: 100%;
  > .MuiAccordionSummary-root {
    background: ${({ theme }) => theme.colors.global.background} !important;
    position: sticky !important;
    top: 0 !important;
    z-index: ${zIndex.dropdown} !important;
  }
  box-shadow: 0px 0px 8.3px 1px rgba(0, 0, 0, 0.16) !important;
  .MuiAccordionDetails-root,
  .MuiCollapse-wrapper,
  [role='region'],
  .MuiCollapse-root {
    height: 100% !important;
    position: relative !important;
    gap: 8px;
  }
  .MuiAccordionDetails-root {
    padding-top: 8px !important;
  }

  .MuiAccordionSummary-root {
    justify-content: flex-start;
  }
  border-radius: 5px;
`;

export const StyledSearch = styled(SearchTextField)<{disabled?: boolean}>`
  width: 100%;
  height: 31px;
  margin-bottom: ${newTypography.unit * 2}px;
  & input {
    font-size: 12px;
    font-family: ${newTypography.primaryFont};
     ::placeholder {
        color: ${newGlobalTheme.colors.text.disabledSave};
    }
  }
  .input-wrapper {
    border-bottom: 1px solid #6F6F6F;
  }

 
`;

export const StyledCenteredLoaderContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: ${zIndex.modalLoading};
`;

export const NoProductText = styled.div<StyledWithThemeProps>`
  margin: auto;
  color: ${({ theme }) => theme.colors.text.secondary};
`;