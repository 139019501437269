import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import Header from 'components/header/Header';
import { ModalHolder } from 'components/shared/modal/ModalHolder';
import ToastContainerWrapper from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import Campaigns from 'pages/campaigns/campaignManagement/Campaigns';
import DoeCampaigns from 'pages/campaigns/doeCampaigns/DoeCampaigns';
import Offers from 'pages/offers/offerManagement/Offers';
import DoeOffers from 'pages/offers/doeOffers/DoeOffers';
import Images from 'pages/settings/images/Images';
import DoeImages from 'pages/settings/doeImages/DoeImages';
import TermsConditions from 'pages/settings/termsConditions/TermsConditions';
import ProductSets from 'pages/settings/productSets/ProductSets';
import LocationSets from 'pages/settings/locationSets/LocationSets';
import Audit from 'pages/reports/audit/Audit';
import { CalendarPage } from 'pages/campaigns/calendar/Calendar';
import { tabName, tabToPathMap } from 'components/header/Header.consts';
import GlobalStyle from 'styles/Global';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { Login } from 'pages/auth';
import { Loader, StyledLoader, StyledLoaderContainer } from 'components/shared/loader';
import ProductChanges from 'pages/reports/productChanges/ProductChanges';
import LocationChanges from 'pages/reports/locationChanges/LocationChanges';
import Dashboard from 'pages/dashboard/Dashboard';
import { marketConfig } from 'app/slices/config';
import { UserRole } from 'utils/types/users';
import SchedulePeriods from 'pages/settings/schedulePeriods/SchedulePeriods';
import { DOEMarketConfigurationKey, MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from 'components/errorBoundaryFallback/ErrorBoundaryFallback';
import ScheduleManagement from 'pages/campaigns/scheduleManagement/ScheduleManagement';
import { closeModal } from 'app/slices/modals';
import { store } from 'app/store';
import { Feature } from 'utils/types/features';
import { defaultTheme } from 'styles/themes/defaultTheme';
import DealPriorityConfiguration from 'pages/settings/dealPriority/DealPriorityConfiguration';
import { LoaderSize } from 'components/shared/loader/Loader.consts';

const UserManagement = React.lazy(() => import('pages/settings/userManagement/UserManagement'));
const Configurations = React.lazy(() => import('pages/configurations/Configurations'));
const Features = React.lazy(() => import('pages/configurations/FeatureToggles'));
const Redemptions = React.lazy(() => import('pages/reports/redemptions/Redemptions'));
const Tags = React.lazy(() => import('pages/settings/tags/Tags'));

type ProtectedRouteProps = RouteProps & {
  roles?: UserRole[];
  configurations?: { configKey: MarketConfigurationKey | DOEMarketConfigurationKey; value: any }[];
  featuresCheck?: { featureKey: Feature; value: any }[];
};

function isValidRedirectUrl(url: string): boolean {
  const validPaths = Object.values(tabToPathMap);
  return validPaths.includes(url);
}

function ProtectedRoute({ ...routeProps }: ProtectedRouteProps) {

  const user = useSelector(users);
  const { config, features } = useSelector(marketConfig);
  const roles = routeProps.roles ?? Object.values(UserRole);
  const configurations = routeProps.configurations || [];
  const featuresCheck = routeProps.featuresCheck || [];
  const userRole = user.user.role;

  const isAuthenticated = process.env.NODE_ENV === 'development' || !!user;
  if (!isAuthenticated || !roles.includes(userRole)) {
    return <Redirect to={{ pathname: `/login` }} />;
  }
  if (
    configurations?.some((configToCheck) => config[configToCheck.configKey] !== configToCheck.value) ||
    featuresCheck?.some(
      (featureToCheck: { featureKey: string | number; value: boolean }) =>
        features[featureToCheck.featureKey] !== featureToCheck.value,
    )
  ) {
    return <Redirect to={{ pathname: `/` }} />;
  }
  return <Route {...routeProps}>{routeProps.children}</Route>;
}
function AppRouter() {
  const user = useSelector(users);
  const history = useHistory();
  const location = useLocation();
  const { config } = useSelector(marketConfig);
  const [redirected, setRedirected] = useState(false);
  const onBackButtonEvent = (e: any) => {
    if (document.getElementById('modal').children.length) {
      e.preventDefault();
      // eslint-disable-next-line no-alert
      if (window.confirm('Your changes will not be saved. Are you sure?')) {
        store.dispatch(closeModal());
      } else {
        window.history.pushState({ modal: 0 }, null, window.location.pathname);
      }
    }
  };

   //For Global Loader Accessibility
   const [loadingMessage, setLoadingMessage] = useState('Loading');
   const [loadingComplete, setLoadingComplete] = useState(false);

   useEffect(() => {
    const locationPathName = location.pathname; 
    if (user === null) {
      if (locationPathName !== '/login') {
        if (!redirected) {
          setRedirected(true);
        }
        history.replace(
          `/login?redirectUrl=${'/'}`,
        );
      }
    } else {
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get('redirectUrl');
      if (redirectUrl) {
        history.replace(isValidRedirectUrl(redirectUrl.split('?')[0]) ? redirectUrl : `/`);
      }
    }
  }, [user, location, history, isValidRedirectUrl, redirected]);

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const configLoading = JSON.stringify(config) === '{}';
  const userLoading = user && user.user === undefined;

   //For Global Loader Accessibility
   useEffect(() => {
    let timeout: any;
    if (userLoading || (user && configLoading)) {
      setLoadingComplete(false);
      setLoadingMessage('Loading');
    } else {
      timeout = setTimeout(() => {
        setLoadingMessage('Loaded');
        setLoadingComplete(true);
      }, 500); // 500ms delay
    }
    return () => clearTimeout(timeout);
  }, [userLoading, configLoading]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <ToastContainerWrapper />
        <div aria-live="assertive" style={{ position: 'absolute', left: '-9999px' }}>
          {loadingMessage}
        </div>
        {userLoading || (user && configLoading) ? (
          <StyledLoaderContainer>
          <StyledLoader
          size={LoaderSize.Medium}
          role="status"
          aria-busy={true}
          />
        </StyledLoaderContainer>
        ) : (
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
          </Switch>
        )}
        <div aria-live="assertive" style={{ position: 'absolute', left: '-9999px' }}>
        {loadingMessage}
        </div>
        {!userLoading && !configLoading && (
          <>
            <Header user={user?.user} />
            <Suspense
              fallback={
                <StyledLoaderContainer>
                  <StyledLoader
                    size={LoaderSize.Medium}
                    role="status"
                    aria-busy={true}
                  />
                 </StyledLoaderContainer>
              }
            >
              <Switch>
                <ProtectedRoute path={tabToPathMap[tabName.Campaigns]}>
                  <Campaigns />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.CampaignManagement]}>
                  <Campaigns />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.ScheduleManagement]}
                  configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
                >
                  <ScheduleManagement />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.Calendar]}
                  featuresCheck={[{ featureKey: Feature.Calendar, value: true }]}
                >
                  <CalendarPage />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.DoeCampaigns]}
                  configurations={[{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true }]}
                >
                  <DoeCampaigns />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.Offers]}>
                  <Offers />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.OfferManagement]}>
                  <Offers />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.DoeOffers]}
                  configurations={[{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true }]}
                >
                  <DoeOffers />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.Images]}>
                  <Images />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.Tags]} roles={[UserRole.SysAdmin, UserRole.Admin]}>
                  <Tags />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.TermsConditions]}
                  roles={[UserRole.SysAdmin, UserRole.Admin]}
                >
                  <TermsConditions />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.ProductSets]} roles={[UserRole.SysAdmin, UserRole.Admin]}>
                  <ProductSets />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.LocationSets]} roles={[UserRole.SysAdmin, UserRole.Admin]}>
                  <LocationSets />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.UserManagement]} roles={[UserRole.SysAdmin, UserRole.Admin]}>
                  <UserManagement />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.SchedulePeriods]}
                  roles={[UserRole.SysAdmin, UserRole.Admin]}
                  configurations={[{ configKey: MarketConfigurationKey.EnableSchedulePeriods, value: true }]}
                >
                  <SchedulePeriods />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.DealsPriorityConfiguration]}
                >
                  <DealPriorityConfiguration />
                </ProtectedRoute>
                <ProtectedRoute
                  path={tabToPathMap[tabName.DoeImages]}
                  configurations={[{ configKey: DOEMarketConfigurationKey.EnableDigitalOffersEngine, value: true }]}
                >
                  <DoeImages />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.Audit]}>
                  <Audit />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.Redemptions]}>
                  <Redemptions />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.ProductChanges]}>
                  <ProductChanges />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.LocationChanges]}>
                  <LocationChanges />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.Configurations]} roles={[UserRole.SysAdmin]}>
                  <Configurations />
                </ProtectedRoute>
                <ProtectedRoute path={tabToPathMap[tabName.Features]} roles={[UserRole.SysAdmin]}>
                  <Features />
                </ProtectedRoute>
                <Route path={tabToPathMap[tabName.Dashboard]}>
                  <Dashboard />
                </Route>
              </Switch>
            </Suspense>
          </>
        )}
        <ModalHolder />
    </ErrorBoundary>
    </ThemeProvider>
  );
}

export default AppRouter;