import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OfferTemplates } from 'utils/types/offers';
import TotalAmount from 'components/shared/totalAmount/TotalAmount';
import { store } from 'app/store';
import { OfferFilters } from 'pages/offers/offerManagement/Offers.const';
import { offersSelection } from 'app/genericSlices/offers';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import {
  NewStyledFilterBarSelectForOfferSelection,
  OfferSelectionContainer,
  StyledSearchTextField,
  StyledSelect,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/components/offerSelectionFilterBar/OfferSelectionFilterBar.style';
import { ApprovalStatus, ApprovalStatusLabel, ApprovalStatusWithLabel } from 'utils/types';
import { Feature } from 'utils/types/features';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import { marketConfig } from 'app/slices/config';
import { Filters, NewStyledFilterBarSelect } from 'pages/shared/shared.style';

const OfferSelectionFilterBar = ({ total, displayTemplate = true }: { total: number; displayTemplate?: boolean }) => {
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Offer]);
  const { filters } = useSelector(offersSelection.offersState);
  const availableStatuses = [ApprovalStatus.Approved, ApprovalStatus.PendingApproval, ApprovalStatus.Draft];

  const reloadOffers = (filter: { [key in OfferFilters]?: any }) => {
    store.dispatch(offersSelection.actions.setFilters({ ...filters, ...filter }));
  };

  useEffect(() => {
    loadTags();
  }, []);
  const { config } = useSelector(marketConfig);
  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => enableDigitalDownload || template.id !== '14'
  );
  return (
    <OfferSelectionContainer>
      <FeatureGuard features={[{ feature: Feature.DraftCampaignOfferEnhancement }]}>
        <NewStyledFilterBarSelectForOfferSelection
          placeholder="Select"
          label="Status"
          name="offer-status"
          multiple
          items={
            availableStatuses.map((status) => {
              return {
                id: status,
                name: ApprovalStatusLabel[status as ApprovalStatusWithLabel],
              };
            }) as any[]
          }
          onChange={(selectedItems) =>
            reloadOffers({
              [OfferFilters.Status]: Object.values(selectedItems).map((i: any) => i.id),
            })
          }
          initialSelectedItems={filters[OfferFilters.Status]}
          reset
          maxItems={1}
          selectWidth={172}
          version="offer-form"
        />
      </FeatureGuard>
      {displayTemplate && (
        <NewStyledFilterBarSelectForOfferSelection
          name="offer-templates"
          placeholder="Select"
          label="Template"
          multiple
          items={Object.values(filteredOfferTemplates) as any[]}
          onChange={(selectedItems) =>
            reloadOffers({ [OfferFilters.TemplateType]: Object.values(selectedItems).map((i: any) => i.id) })
          }
          valueField="name"
          withSearch
          reset
          maxItems={1}
          selectWidth={170}
          version="offer-form"
      />
      )}
      <NewStyledFilterBarSelectForOfferSelection
        name="offer-tags"
        placeholder="Select"
        label="Tags"
        multiple
        items={tags}
        onChange={(selectedItems) =>
          reloadOffers({ [OfferFilters.Tags]: Object.values(selectedItems).map((i: any) => i.id) })
        }
        withSearch
        reset
        maxItems={1}
        selectWidth={170}
        version="offer-form"
      />
      <StyledSearchTextField
        name="offers-search"
        value={filters[OfferFilters.SearchQuery] ?? ''}
        onChange={(e) => reloadOffers({ [OfferFilters.SearchQuery]: e.target.value !== '' ? [e.target.value] : '' })}
      />
      <TotalAmount amount={total} />
    </OfferSelectionContainer>
  );
};

export default OfferSelectionFilterBar;
