/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ActionMeta, components, OptionProps } from 'react-select';
import {
  InputContainer,
  LimitIndication,
  SelectboxContainer,
  StyledCheckbox,
  StyledError,
  StyledOption,
  StyledSelect,
} from 'components/shared/selectbox/NewSelectbox.style';
import { StyledSelectboxLabel } from 'components/shared/textField/NewTextField.style';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import NewChip from 'components/shared/chip/NewChip';
import { convertIdsArrayToGenericItemsArray } from 'utils/mapping';
import { newAppTheme, newGlobalTheme } from 'styles/themes/newGlobalTheme';
import useOnClickOutside from 'hooks/use-onclick-outside';
import zIndex from 'styles/zIndex';
import { getIsControlWithError } from 'utils/form';
import { get, set } from 'lodash';
import { campaignFormVersion, offerFormVersion, SelectboxProps } from './NewSelectbox.consts';

const getOfferFormStyle = (style: string, hasError: boolean, theme: newAppTheme, isDisabled = false, isTab = false) => {
  let returnStyle;
    switch(style) {
      case('color'):
        returnStyle =  isDisabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary;
        break;
      case('borderBottom'):
        returnStyle = hasError ? theme.colors.global.error : theme.colors.global.border;
        break;
      case('backgroundColorHover'):
        returnStyle = !isDisabled ? theme.colors.global.selectedBackground : undefined;
        break;
      case('boxShadow'):
        returnStyle = isTab ? `0 0 0 2px ${theme.colors.global.tabNavigation}` : 'none';
        break;
    }
    return returnStyle;
}

const isOfferOrCampaign = (version?: string)  => {
  return (version === offerFormVersion || version === campaignFormVersion)

}

const getVersionStyle = (style: string, version: string, theme: newAppTheme, hasError: boolean, isDisabled = false,) => {
  let returnStyle;
  switch(style) {
    case('color'):
      returnStyle =  isOfferOrCampaign(version) ? getOfferFormStyle('color', hasError, theme, isDisabled, false) :
      theme.colors.text.primary;
      break;
    case('campaignColor'):
      returnStyle = '#ADADAD'
      break;
    case('borderBottom'):
      returnStyle =  isOfferOrCampaign(version) ? 'none' :
      `1px solid ${getOfferFormStyle('borderBottom', hasError, theme, isDisabled, false)}`;
      break;
    case('backgroundColor'):
        returnStyle =  isOfferOrCampaign(version) ? newGlobalTheme.colors.global.background :
        theme.colors.global.tileBackground;
        break;
    case('backgroundColorHover'):
      returnStyle =  isOfferOrCampaign(version) ? newGlobalTheme.colors.status.draft :
      getOfferFormStyle('backgroundColorHover', hasError, theme, isDisabled, false);
      break;
    case('fontSize'):
        returnStyle =  isOfferOrCampaign(version) ? '12px' : '14px';
        break;
    case('placeholderColor'):
        returnStyle = version === offerFormVersion ? newGlobalTheme.colors.text.disabledSave :
        newGlobalTheme.colors.text.input;
        break;
  }
  return returnStyle;
}

const getMenuStyle = (style: string, version: string, name: string, theme: newAppTheme) => {
  let returnStyle;
  switch(style) {
    case('menuWidth'):
      returnStyle =  (version === offerFormVersion || version === campaignFormVersion) && name === 'versions.0.templateValues.product' ? '200px' : 'auto';
    break;
    case('menuBorder'):
      returnStyle =  (version === offerFormVersion || version === campaignFormVersion) ? 'none' : `1px solid ${theme.colors.global.border}`;
    break;
  }
  return returnStyle;
}

export const NewSelectbox = ({
  items,
  initialSelectedItems,
  label,
  placeholder = 'Select',
  name,
  valueField = 'id',
  labelField = 'name',
  disabled,
  clearOnDisabled,
  multiple,
  withSearch = false,
  labelIsHorizontal,
  allowSelectAll,
  allSelected,
  withAmount,
  reset,
  defaultValue,
  control,
  validation,
  errors,
  limit,
  maxItems,
  onChange,
  onCreateOption,
  selectWidth,
  selectHeight,
  className,
  theme = newGlobalTheme,
  getIsOptionDisabled,
  version,
  containerGap,
}: SelectboxProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const ref = useRef();

  const handleClickOutside = () => {
    setIsOpen(false);
    setIsTab(false);
  }
  useOnClickOutside(ref, handleClickOutside);
  const memoizedValidation = useMemo(() => validation, [validation]);
  const allOption = {
    [valueField]: '*',
    [labelField]: 'All',
  };
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(items);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allMultiValuePresent, setAllMultiValuePresent] = useState(false);


  const getRegularValue = () =>
    allowSelectAll && allSelected
      ? [allOption, ...items]
      : initialSelectedItems && items
      ? convertIdsArrayToGenericItemsArray(initialSelectedItems, items, valueField)
      : defaultValue;

  const getControlledValue = (fieldValue: any): any[] => {
    if (!fieldValue) {
      return getRegularValue();
    }
    return items
      ? multiple
        ? convertIdsArrayToGenericItemsArray(
            fieldValue.map((i: any) => (typeof i === 'object' ? i[valueField] : i)),
            items,
            valueField,
          )
        : items?.find((option) => option[valueField] === fieldValue)
      : defaultValue;
  };

  const [value, setValue] = useState(
    control ? getControlledValue(get(control._defaultValues, name)) : getRegularValue(),
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'ArrowDown' && isOpen === false) {
      setIsOpen(true);
    }
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setOptions(items);
  }, [items]);

  const Option = (props: OptionProps<any> & { value: any }) => {
    const { value: optionValue, label: optionLabel, isSelected } = props;
    const isSelectAllIntermediate =
      multiple &&
      allowSelectAll &&
      optionValue?.value === allOption[valueField] &&
      value?.length > 0 &&
      value?.length < options.length;
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {multiple && optionValue ? (
          <components.Option {...props}>
            <StyledOption onKeyDown={handleKeyDown} tabIndex={0} title={optionLabel}>
              <StyledCheckbox onClick={null} checked={isSelected} intermediate={isSelectAllIntermediate} />
              <label>{optionLabel}</label>
            </StyledOption>
          </components.Option>
        ) : (
          <components.Option {...props} />
        )}
      </div>
    );
  };

  const getMultiValue = (index : any, overflowItems : any) => {
    return index === maxItems ? (
      <NewChip extra onClose={null} title={overflowItems.join(', ')}>{`+${overflowItems.length}`}</NewChip>
    ) : null
  }

  useEffect(() => {
    setAllMultiValuePresent(false)
  }, [value])

  const MultiValue = ({ index, getValue, children, ...props }: any) => {
    const overflowItems = maxItems
        ? getValue().slice(maxItems).map((x: any) => x[labelField])
        : null;

    if (props.data[labelField] === 'All') {
        setAllMultiValuePresent(true);
        return (
            <NewChip
                first
                title={props.data[labelField]}
                {...{
                    onClose: !disabled
                        ? (e) => {
                            e.stopPropagation();
                            props.removeProps.onClick();
                        }
                        : null,
                }}
            >
                {props.data[labelField]}
            </NewChip>
        );
    }

    if (allMultiValuePresent) {
        return null;
    }

    return (
        <div onKeyDown={handleKeyDown}>
            {!maxItems || index < maxItems ? (
                <NewChip
                    first
                    title={props.data[labelField]}
                    {...{
                        onClose: !disabled
                            ? (e) => {
                                e.stopPropagation();
                                props.removeProps.onClick();
                            }
                            : null,
                    }}
                >
                    {props.data[labelField]}
                </NewChip>
            ) : getMultiValue(index, overflowItems)}
        </div>
    );
};

  const handleChange = (newValue: any, event: any) => {
    if (allowSelectAll && multiple) {
      if (
        (event.action === 'remove-value' && event.removedValue[valueField] === allOption[valueField]) ||
        (event.action === 'deselect-option' && event.option[valueField] === allOption[valueField])
      ) {
        setValue([]);
        if (onChange) {
          onChange([]);
        }
        return;
      }
      if (newValue?.length > 0) {
        if (newValue[newValue.length - 1][valueField] === allOption[valueField]) {
          setValue([allOption, ...options]);
          if (onChange) {
            onChange(options);
          }
          return;
        }
        let result = [];
        if (newValue.length === options.length) {
          result = newValue.filter((option: any) => option[valueField] !== allOption[valueField]);
          if (event.action === 'select-option') {
            result = [allOption, ...options];
          }
          setValue(result);
          if (onChange) {
            onChange(newValue);
          }
          return;
        }
      }
    }
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
    if (!multiple) {
      setIsOpen(false);
    }
    if (!newValue || newValue?.length === 0) {
      setTimeout(() => (document.activeElement as HTMLElement).blur(), 100);
    }
    if (name === 'localSchedule.period' && event.action === 'clear') {
      set(control, '_defaultValues.' + name, newValue);
    }
  };

  const handleCreate = async (inputValue: string, field: ControllerRenderProps<FieldValues, string>) => {
    setIsLoading(true);
    setIsOpen(false);
    const newOption = await onCreateOption(inputValue);
    if (newOption) {
      setOptions([newOption, ...options]);
      setValue([newOption, ...(value || [])]);
    }
    setIsLoading(false);
    if (field) {
      field.onChange([newOption, ...(value || [])].map((v: any) => v.id));
    }
  };

  const getOptionName = (option: any) =>
    withAmount && option.amount ? `${option[labelField]} (${option.amount})` : option[labelField];

  const getOptionLabel = (option: any) => {
    if (onCreateOption) {
      return option.__isNew__ ? option.label : getOptionName(option);
    }
    return getOptionName(option);
  };

  const hasError = getIsControlWithError(name, errors);

  const getControlHeight = () => {
    if (version === offerFormVersion) {
      return '36px';
    }
    if (version === campaignFormVersion) {
      return '35px';
    }
    return null;
  }

  const getContainerWidth = () => {
    return selectWidth ? selectWidth : 'auto';
  }

  const getContainerHeight = () => {
    return selectHeight ? selectHeight : 'auto';
  }
  const customStyles = useMemo(
    () => ({
      valueContainer: (provided: any) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        padding: '0',
        display: 'flex',
        fontSize: '12px',
        fontWeight: '400',
        position: 'relative',
        height: getControlHeight(),
        fontFamily: 'Speedee',
      }),
      singleValue: (provided: any, { isDisabled }: any) => ({
        ...provided,
        color: getVersionStyle('color', version, theme, hasError, isDisabled),
        fontFamily: 'Speedee',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        height: getControlHeight(),
        borderRadius: 0,
        border: 'none',
        boxShadow:  getOfferFormStyle('boxShadow', false, theme, false, state.isFocused && isTab),
        borderBottom: getVersionStyle('borderBottom', version, theme, hasError, false),
        backgroundColor: getVersionStyle('backgroundColor', version, theme, hasError, false),
        minHeight: '28px',
        ':hover': {
          ...provided[':hover'],
          borderColor: theme.colors.text.disabled,
          borderBottom: getVersionStyle('borderBottom', version, theme, hasError, false)
        },
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
        transform: 'scale(0.8)',
        cursor: 'pointer',
        color: theme.colors.global.border, 
        ':hover': {
          ...provided[':hover'],
          color: theme.colors.text.disabled,
        },
      }),
      clearIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
        transform: 'scale(0.8)',
        cursor: 'pointer',
        color: theme.colors.global.border,
        ':hover': {
          ...provided[':hover'],
          color: theme.colors.text.disabled,
        },
      }),
      option: (provided: any, { isDisabled, isFocused }: any) => ({
        ...provided,
        color: isDisabled ? theme.colors.text.disabled : theme.colors.text.primary,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: getVersionStyle('fontSize', version, theme, hasError, isDisabled),
        backgroundColor: isFocused ? newGlobalTheme.colors.status.draft : 'none',
        ':hover': {
          ...provided[':hover'],
          backgroundColor: getVersionStyle('backgroundColorHover', version, theme, hasError, isDisabled)
        },
      }),
      container: (base: any) => ({ ...base, width: getContainerWidth(), height: getContainerHeight()}),
      placeholder: (provided: any) => ({
        ...provided,
        fontWeight: '400',
        position: 'absolute',
        color: version === 'campaign-form' ? getVersionStyle('campaignColor', version, theme, hasError, false) :
        getVersionStyle('placeholderColor', version, theme, hasError, false),
        fontFamily: 'Speedee',
      }),
      menu: (provided: any) => ({
        ...provided,
        border:  getMenuStyle('menuBorder', version, name, theme),
        boxShadow: '1px 1px 10px 0 rgb(0 0 0 / 22%)',
        zIndex: zIndex.dropdown,
        minWidth: '100%',
        maxWidth: '500px',
        width: getMenuStyle('menuWidth', version, name, theme)
      }),
      menuList: (provided: any) => ({
        ...provided,
        maxHeight: '190px',
      }),
    }),
    [hasError, isTab],
  );

  useEffect(() => {
    if (initialSelectedItems && !control) {
      setValue(getRegularValue());
    }
  }, [initialSelectedItems]);

  useEffect(() => {
    const fieldValue = get(control?._defaultValues, name);
    if (fieldValue) {
      setValue(getControlledValue(fieldValue));
    }
  }, [get(control?._defaultValues, name)]);


  useEffect(() => {
    if (disabled && clearOnDisabled) {
      setValue(null);
    }
  }, [disabled]);

    window.addEventListener('keydown', (e) => {
    if (e.key === 'Tab') {
        setIsTab(true);
    }
  });

  const IndicatorsContainer = ({ index, getValue, children, ...props }: any) => {
    return <>
     {limit ? <LimitIndication>{`${value?.length || 0} of ${limit}`}</LimitIndication> : null}
     {children}
    </>
  }

  const getOptions = () => {
    return multiple && allowSelectAll ? [allOption, ...options] : options
  }

  return (
    <SelectboxContainer
      labelIsHorizontal={labelIsHorizontal}
      className={className}
      data-automation-id={`selectbox-${name}`}
      onClick={() => {
        if (!disabled && !isLoading) {
          setIsOpen(!isOpen);
          setIsTab(false);
        }
      }}
      onKeyDown={handleKeyDown}
      ref={ref}
      containerGap={containerGap}
    >
      {label && (
        <StyledSelectboxLabel version={version} theme={theme} disabled={disabled} labelIsHorizontal={labelIsHorizontal}>{`${label}${
          validation?.required ? '*' : ''
        }`}</StyledSelectboxLabel>
      )}
      <InputContainer disabled={disabled} onKeyDown={handleKeyDown} version={version} data-automation-id={`selectbox-input-container-${name}`} >
        {control ? (
          <Controller
            control={control}
            name={name}
            rules={memoizedValidation}
            defaultValue={defaultValue}
            render={({ field }) => (
              <StyledSelect
                onKeyDown={handleKeyDown}
                getOptionLabel={getOptionLabel}
                getOptionValue={(option: any) => option[valueField]}
                options={getOptions()}
                name={name}
                tabIndex={0}
                placeholder={placeholder}
                isDisabled={disabled || isLoading}
                isSearchable={withSearch}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti={multiple}
                isClearable={reset}
                onChange={(newValue: any, event: ActionMeta<any>) => {
                  if (newValue) {
                    field.onChange(multiple ? newValue.map((item: any) => item[valueField]) : newValue[valueField]);
                  } else if (field.name === 'localSchedule.period' && !newValue) {
                    field.onChange(newValue);
                  }
                  handleChange(newValue, event);
                }}
                defaultValue={defaultValue}
                value={value}
                {...(onCreateOption && {
                  isLoading,
                  onCreateOption: (val: any) => handleCreate(val, field),
                })}
                isOptionDisabled={(option: any) => limit && options?.length > limit && value?.length >= limit && !value?.includes(option)}
                components={{ Option, MultiValue,  IndicatorsContainer, IndicatorSeparator: () : any => null}}
                styles={customStyles}
                width={selectWidth}
                height={selectHeight}
                menuIsOpen={isOpen}
              />
            )}
          />
        ) : (
          <StyledSelect
            getOptionLabel={getOptionLabel}
            getOptionValue={(option: any) => option[valueField]}
            options={multiple && allowSelectAll ? [allOption, ...options] : options}
            name={name}
            placeholder={placeholder}
            isDisabled={disabled || isLoading}
            isSearchable={withSearch}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isMulti={multiple}
            isClearable={reset}
            onChange={handleChange}
            defaultValue={defaultValue}
            value={value}
            {...(onCreateOption && { isLoading, onCreateOption: handleCreate })}
            isOptionDisabled={(option: any) => limit && options?.length > limit && value?.length >= limit && !value?.includes(option)}
            components={{ Option, MultiValue, IndicatorsContainer, IndicatorSeparator: () : any => null}}
            styles={customStyles}
            width={selectWidth}
            height={selectHeight}
            menuIsOpen={isOpen}
          />
        )}
        <StyledError name={name} errors={errors} />
      </InputContainer>
    </SelectboxContainer>
  );
};
